












import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { Direction } from '../types';
import { EventAttachment } from '@/repositories/types';

@Component
export default class TimeLineEvent extends Vue {
    @Prop() public name!: string;
    @Prop() public text!: string;
    @Prop() public condensed!: boolean;
    @Prop() public date!: Date;
    @Prop() public icon!: null|string;
    @Prop() public direction!: Direction;
    @Prop() public urls!: EventAttachment[];
    @Prop() public images!: EventAttachment[];

    public hover: boolean = false;
    public contentHeight: string = '0';

    @Watch('hover')
    public onHoverChanged(val: boolean, oldVal: boolean) {
        if (!this.condensed) {
            return;
        }

        this.contentHeight = val ? this.getPotentialContentHeight().toString() + 'px' : '0';
    }

    get className(): string {
        let classname = 'event ';

        if (this.condensed) {
            classname += 'condensed ';
        }

        classname += this.direction;
        return classname;
    }

    get humanReadableDate(): string {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        return `${months[this.date.getMonth()]} ${this.date.getDate()}, ${this.date.getUTCFullYear()}`;
    }

    private getPotentialContentHeight(): number {

        // @ts-ignore
        const clone = this.$refs.event.cloneNode(true);
        clone.className = clone.className + ' getHeightClone';

        const body = document.querySelector('.timeline');
        if (body === null) {
            return 0;
        }
        body.appendChild(clone);

        clone.querySelector('.content').style.height = 'auto';
        const height = parseInt(clone.querySelector('.content').offsetHeight);
        body.removeChild(clone);

        return height;
    }
}
