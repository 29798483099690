














import { Component, Vue } from 'vue-property-decorator';
import BoycottBlizzard from '@/components/Support/BoycottBlizzard.vue';
import FreeHongKong from '@/components/Support/FreeHongKong.vue';

@Component({
    components: { FreeHongKong, BoycottBlizzard },
})

export default class SupportSection extends Vue {
}
