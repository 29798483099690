















import { Component, Prop, Vue } from 'vue-property-decorator';
import TimelineEventsRepository from '@/repositories/TimelineEventsRepository';
import { Event, EventAttachment, EventAttachmentType } from '@/repositories/types';
import TimeLineEvent from '@/components/TimeLine/TimeLineEvent.vue';
import { Direction } from '@/components/types';

@Component({
    components: { TimeLineEvent },
})

export default class TimeLine extends Vue {
    @Prop({
        default: () => {
            return new TimelineEventsRepository();
        },
    }) public timelineEventsRepository!: TimelineEventsRepository;

    public events: Event[] = [];

    public getEvents(): void {
        this.events = this.timelineEventsRepository.getEvents();
    }

    public getDirection(event: Event): Direction {
        const eventIndex = this.events.indexOf(event);
        let direction = Direction.right;

        let currentIndex = 0;
        do {
            if (!this.events[currentIndex].condensed) {
                direction = (direction === Direction.right ? Direction.left : Direction.right);
            }

            currentIndex++;
        }
        while (eventIndex >= currentIndex);

        return direction;
    }

    public filterUrls(attachment: EventAttachment): boolean {
        return attachment.type === EventAttachmentType.url;
    }

    public filterImages(attachment: EventAttachment): boolean {
        return attachment.type === EventAttachmentType.image;
    }

    private mounted(): void {
        this.getEvents();
    }
}
