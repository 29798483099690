














import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommunityArtAuthor } from '@/repositories/types';

@Component({})
export default class CommunityArtItem extends Vue {
    @Prop() public image!: string;
    @Prop() public label!: string;
    @Prop() public author!: CommunityArtAuthor;
}
