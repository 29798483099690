









import { Component, Vue } from 'vue-property-decorator';
import TimeLine from '@/components/TimeLine/TimeLine.vue';
import { jarallax } from 'jarallax';

@Component({
    components: { TimeLine },
})

export default class TimeLineSection extends Vue {
    private mounted() {
        jarallax(document.querySelector('section#timeline'), {
            speed: 0.6,
        });
    }
}
