










import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class SiteHero extends Vue {
    public spanOneIsVisible: boolean = false;
    public spanTwoIsVisible: boolean = false;
    public spanThreeIsVisible: boolean = false;

    private lineDelay: number = 1400;

    private mounted() {
        setTimeout(() => {
            this.spanOneIsVisible = true;
        }, this.lineDelay);

        setTimeout(() => {
            this.spanTwoIsVisible = true;
        }, (this.lineDelay + 100) * 2);

        setTimeout(() => {
            this.spanThreeIsVisible = true;
        }, (this.lineDelay + 100) * 3);
    }
}
