
















import { Component, Prop, Vue } from 'vue-property-decorator';
import CommunityArtRepository from '@/repositories/CommunityArtRepository';
import { CommunityArt } from '@/repositories/types';
import CommunityArtItem from '@/components/CommunityArt/CommunityArtItem.vue';

@Component({
    components: { CommunityArtItem },
})
export default class CommunityArtGrid extends Vue {
    @Prop({
        default: () => {
            return new CommunityArtRepository();
        },
    }) public communityArtRepository!: CommunityArtRepository;

    public communityArt: CommunityArt[] = [];

    public getFanArt(): void {
        this.communityArt = this.communityArtRepository.getArt();
    }

    private mounted() {
        this.getFanArt();
    }
}
