






















































import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

interface ErrorMessages {
    name: null|string;
    email: null|string;
    message: null|string;
}

@Component({})
export default class ContactForm extends Vue {

    public name: string = '';
    public email: string = '';
    public message: string = '';
    public invalidFields: string[] = [];
    public errorMessages: ErrorMessages = {
        name: null,
        email: null,
        message: null,
    };
    public loading: boolean = false;
    public successful: null|boolean = null;
    public submitMessage: string = '';

    get nameIsValid(): boolean {
        return !this.invalidFields.includes('name');
    }

    get emailIsValid(): boolean {
        return !this.invalidFields.includes('email');
    }

    get messageIsValid(): boolean {
        return !this.invalidFields.includes('message');
    }

    get submitMessageClass(): string {
        return 'message' + (this.successful === true ? ' success' : ' error');
    }

    get shouldDisplaySubmitMessage(): boolean {
        return this.successful !== null;
    }

    public validateName(): boolean {
        if (this.name.length <= 0) {
            this.pushInvalidField('name');
            this.errorMessages.name = 'Name is required.';
            return false;
        }

        this.removeInvalidField('name');
        this.errorMessages.name = null;
        return true;
    }

    public validateEmail(): boolean {
        if (this.email.length <= 0) {
            this.pushInvalidField('email');
            this.errorMessages.email = 'Email is required.';
            return false;
        }

        if (! /\S+@\S+\.\S+/.test(this.email)) {
            this.pushInvalidField('email');
            this.errorMessages.email = 'Email is invalid.';
            return false;
        }

        this.removeInvalidField('email');
        this.errorMessages.email = null;
        return true;
    }

    public validateMessage(): boolean {

        if (this.message.length <= 0) {
            this.pushInvalidField('message');
            this.errorMessages.message = 'Message is required.';
            return false;
        }

        this.removeInvalidField('message');
        this.errorMessages.message = null;
        return true;
    }

    public postMessage(): void {
        const nameValid = this.validateName();
        const emailValid = this.validateEmail();
        const messageValid = this.validateMessage();

        if (!nameValid || !emailValid || !messageValid) {
            return;
        }

        this.loading = true;

        axios.post(
            '/',
            this.encode({
                'form-name': 'submit-new',
                name: this.name,
                email: this.email,
                message: this.message,
            }),
            {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            },
        )
            .then(() => {
                this.name = '';
                this.email = '';
                this.message = '';
                this.successful = true;
                this.submitMessage = 'Your message has been successfully submitted.';
                this.loading = false;
            })
            .catch(() => {
                this.successful = false;
                this.submitMessage = 'There was a problem submitting your message. Please try again.';
                this.loading = false;
            });
    }

    private pushInvalidField(field: string): void {
        if (!this.invalidFields.includes(field)) {
            this.invalidFields.push(field);
        }
    }

    private removeInvalidField(field: string): void {
        const index = this.invalidFields.indexOf(field);
        if (index > -1) {
            this.invalidFields.splice(index, 1);
        }
    }

    private encode(data: any) {
        return Object.keys(data)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
            .join('&');
    }
}
