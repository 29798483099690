






import { Component, Vue } from 'vue-property-decorator';
import ContactForm from '@/components/Contact/ContactForm.vue';

@Component({
    components: { ContactForm },
})

export default class ContactSection extends Vue {

}
