import { render, staticRenderFns } from "./SummarySection.vue?vue&type=template&id=490a8f93&scoped=true&"
import script from "./SummarySection.vue?vue&type=script&lang=ts&"
export * from "./SummarySection.vue?vue&type=script&lang=ts&"
import style0 from "./SummarySection.vue?vue&type=style&index=0&id=490a8f93&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "490a8f93",
  null
  
)

export default component.exports