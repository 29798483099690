












import { Component, Prop, Vue } from 'vue-property-decorator';
import ChevronRight from './../assets/images/chevron-right-solid.svg?inline';
import ChevronLeft from './../assets/images/chevron-left-solid.svg?inline';

@Component({
    components: { ChevronRight, ChevronLeft },
})
export default class AnnouncementSection extends Vue {
    @Prop() url!: string;
    @Prop() text!: string;
}
