













import Vue from 'vue';
import Component from 'vue-class-component';
import SiteFooter from '@/components/SiteFooter.vue';

@Component({
    components: { SiteFooter },
})

export default class App extends Vue {
    private prevHeight: null|string = '0';

    public beforeLeave(element: HTMLElement): void {
        this.prevHeight = getComputedStyle(element).height;
    }

    public enter(element: HTMLElement): void {
        const { height } = getComputedStyle(element);

        element.style.height = this.prevHeight;

        setTimeout(() => {
            element.style.height = height;
        });
    }

    public afterEnter(element: HTMLElement): void {
        element.style.height = 'auto';
    }
}
