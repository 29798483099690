









import { Component, Vue } from 'vue-property-decorator';
import CommunityArtGrid from '@/components/CommunityArt/CommunityArtGrid.vue';
import { jarallax } from 'jarallax';

@Component({
    components: { CommunityArtGrid },
})
export default class CommunityArtSection extends Vue {
    private mounted() {
        jarallax(document.querySelector('section#community-art'), {
            speed: 0.6,
        });
    }
}
